import Vuex from 'vuex';
import axios from 'axios';

export default new Vuex.Store({
    state: {
        current_domain_id: (localStorage.getItem('current_domain_id') ? parseInt(localStorage.getItem('current_domain_id')) : null),
        status: '',
        token: window.$cookies.get('token') || '', //localStorage.getItem('token') || '',
        user: {}
    },
    mutations: {
        updateDomainID(state, domain_id) {
            state.current_domain_id = domain_id;
            localStorage.setItem('current_domain_id', domain_id);
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                delete axios.defaults.headers.common['Authorization']
                window.$cookies.remove('token', '/', process.env.MIX_ROOT_APP_URL)
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        current_domain_id: state => state.current_domain_id,
    }
})
