<template>
    <div>
        <layout-header title="Create New Tag"></layout-header>

        <form-section title="Tag Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="tag.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="tag.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-label id="color" label="Color"></form-label>
                <VSwatches
                    v-model="tag.color"
                    shapes="circles"
                    background-color="transparent"
                    inline
                ></VSwatches>
                <form-validation param="color" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Tag
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormLabel from '../../shared/form/Label';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'CreateTag',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormLabel,
            FormInput,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
        },
        data() {
            return {
                tag: {
                    name: '',
                    color: '',
                    status: 'active',
                },
                errors: [],
            }
        },
        methods: {
            save() {
                this.$http.post('/api/v1/tags', this.tag).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The tag has been created',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({name: 'list_tags'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            }
        }
    }

</script>

<style scoped>

</style>
