<template>
    <td class="py-4 px-4 text-gray-700 break-all" v-bind:class="{'text-right': last, 'hidden md:table-cell': hideSm, 'hidden lg:table-cell': hideMd, 'hidden xl:table-cell': hideLg, 'whitespace-nowrap': noWrap, 'font-bold': bold}">
        <slot></slot>
    </td>
</template>

<script>

    export default {
        name: 'table-cell',
        props: {
            last: Boolean,
            hideSm: Boolean,
            hideMd: Boolean,
            hideLg: Boolean,
            noWrap: Boolean,
            bold: Boolean,
        },
    };
</script>
