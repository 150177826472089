import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/app/Home';

import ListDomains from './components/app/domains/ListDomains';
import CreateDomain from './components/app/domains/CreateDomain';
import EditDomain from './components/app/domains/EditDomain';

import ListTags from './components/app/tags/ListTags';
import CreateTag from './components/app/tags/CreateTag';
import EditTag from './components/app/tags/EditTag';

import ListLinks from './components/app/links/ListLinks';
import CreateLink from './components/app/links/CreateLink';
import EditLink from './components/app/links/EditLink';

import ListReports from './components/app/reports/ListReports';
import CreateReport from './components/app/reports/CreateReport';

import NotFound from './components/app/NotFound';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/domains',
            name: 'list_domains',
            component: ListDomains
        },
        {
            path: '/domains/create',
            name: 'create_domain',
            component: CreateDomain
        },
        {
            path: '/domains/edit/:id',
            name: 'edit_domain',
            component: EditDomain,
            props: true
        },
        {
            path: '/tags',
            name: 'list_tags',
            component: ListTags
        },
        {
            path: '/tags/create',
            name: 'create_tag',
            component: CreateTag
        },
        {
            path: '/tags/edit/:id',
            name: 'edit_tag',
            component: EditTag,
            props: true
        },
        {
            path: '/links',
            name: 'list_links',
            component: ListLinks
        },
        {
            path: '/links/create',
            name: 'create_link',
            component: CreateLink
        },
        {
            path: '/links/edit/:id',
            name: 'edit_link',
            component: EditLink,
            props: true
        },
        {
            path: '/reports',
            name: 'list_reports',
            component: ListReports
        },
        {
            path: '/reports/create',
            name: 'create_report',
            component: CreateReport
        },
    ]
});

export default router;
