<template>
    <div>
        <layout-header title="Create New Report"></layout-header>

        <form-section title="Report Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="report.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="report.type">
                    <option value="">- Select Report Type -</option>
                    <option value="hit_miss_summary">Hit/Miss Summary</option>
                    <option value="browser_summary">Browser Summary</option>
                    <option value="os_summary">OS Export</option>
                    <option value="request_details_export">Request Details Export</option>
                    <option value="miss_details_export">Miss Details Export</option>
                    <option value="health_check_export">Health Check Export</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>

            <form-field v-if="showDateRange()">
                <form-date-picker v-model="report.parameters.daterange" id="daterange" label="Date Range" type="daterange" :range="true"></form-date-picker>
                <form-validation param="date_start" :errors="errors" />
                <form-validation param="date_end" :errors="errors" />
            </form-field>

            <form-field v-if="showGroupBy()">
                <form-select id="group_by" name="group_by" label="Group By" v-model="report.parameters.group_by">
                    <option value="">- Select Group By -</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                </form-select>
                <form-validation param="group_by" :errors="errors" />
            </form-field>

            <form-field v-if="showLinkSelect()">
                <form-label label="Links" for="links"></form-label>
                <multiselect v-model="report.parameters.links" :options="links" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" :custom-label="customLabel" placeholder="Select a Link"></multiselect>
                <form-validation param="link_ids" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Report
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone'
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormLabel from '../../shared/form/Label';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatePicker from '../../shared/form/DatePicker';

    export default {
        name: 'CreateReport',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormLabel,
            FormInput,
            FormSelect,
            FormButton,
            FormValidation,
            FormDatePicker,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchLinks()
        },
        data() {
            return {
                daterange: {
                    lang: {
                        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                        placeholder: {
                        date: 'Select Date',
                        dateRange: 'Select Date Range'
                        }
                    },
                },
                links: [],
                report: {
                  name: '',
                  domain_id: this.$store.getters.current_domain_id,
                  type: '',
                  parameters: {
                      date_range: '',
                      group_by: '',
                      links: [],
                  }
                },
                errors: []
            }
        },
        methods: {
            fetchLinks() {
                this.$http.get(`/api/v1/links?per_page=-1&domain_id=${this.$store.getters.current_domain_id}`).then(this.refreshLinks);
            },
            refreshLinks({ data }) {
                this.links = data.data;
            },
            save() {
              let report = this.buildReport()

              this.$http.post('/api/v1/reports', report).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The report has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_reports'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the report',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showGroupBy() {
                if (this.report.type === 'hit_miss_summary' || this.report.type === 'browser_summary' || this.report.type === 'os_summary')
                    return true
                else
                    return false
            },
            showLinkSelect() {
                if (this.report.type === 'hit_miss_summary' || this.report.type === 'browser_summary' || this.report.type === 'os_summary' || this.report.type === 'request_details_export')
                    return true
                else
                    return false
            },
            showDateRange() {
                if (this.report.type === 'hit_miss_summary' || this.report.type === 'browser_summary' || this.report.type === 'os_summary' || this.report.type === 'request_details_export' || this.report.type === 'miss_details_export')
                    return true
                else
                    return false
            },
            buildReport() {
                let report = {
                    name: this.report.name,
                    domain_id: this.$store.getters.current_domain_id,
                    type: this.report.type,
                    parameters: {
                        date_start: moment(this.report.parameters.date_range[0]).format('YYYY-MM-DD'),
                        date_end: moment(this.report.parameters.date_range[1]).format('YYYY-MM-DD'),
                    }
                }

                if (this.report.type === 'hit_miss_summary' || this.report.type === 'browser_summary' || this.report.type === 'os_summary') {
                    report.parameters.group_by = this.report.parameters.group_by
                }

                if (this.report.type === 'hit_miss_summary' || this.report.type === 'browser_summary' || this.report.type === 'os_summary' || this.report.type === 'request_details_export') {
                    report.parameters.link_ids = []

                    for (let i in this.report.parameters.links) {
                        report.parameters.link_ids.push(this.report.parameters.links[i].id)
                    }
                }

                return report
            },
            customLabel ({ name, path }) {
              return `${name} (${path})`
            }
        }
    }
</script>
