import moment from 'moment-timezone';

function capitalize(value) {
    if (!value)
        return value;

    let frags = value.split('_');
    for (let i = 0; i < frags.length; ++i) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function formatDate(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm:a z');
    }
}

export { capitalize, formatDate };
