<template>
    <div>
        <layout-header title="Domains">
            <layout-button href="/domains/create">Create New Domain</layout-button>
        </layout-header>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('name')">Name</table-header>
                    <table-header @click="updateSort('url')">Domain</table-header>
                    <table-header @click="updateSort('scope')">Scope</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(domain, index) in domains" :key="domain.id" :index="index">
                    <table-cell hide-sm bold>{{ domain.id }}</table-cell>
                    <table-cell>{{ domain.name }}</table-cell>
                    <table-cell>{{ domain.url }}</table-cell>
                    <table-cell>{{ this.$filters.capitalize(domain.scope) }}</table-cell>
                    <table-cell hide-sm><table-status-badge :status="domain.status"></table-status-badge></table-cell>
                    <table-cell last>
                        <table-edit-link :href="{ name: 'edit_domain', params: { id: domain.id }}" v-if="domain.scope === 'client'"></table-edit-link>
                        <table-delete-link @click="confirmDelete(domain.id)" v-if="domain.scope === 'client'"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination flex justify-center gap-2'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete Domain?"
            description="Are you sure you want to delete this domain?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteDomain()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import ConfirmModal from '../../shared/modal/ConfirmModal'

    export default {
        name: 'ListDomains',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(this.current_page);
        },
        data() {
            return {
                domains: [],
                delete_id: null,
                page_count: 1,
                current_page: this.$route.query.page || 1,
                sort: this.$route.query.sort || 'desc',
                order: this.$route.query.order || 'id',
                open_delete_modal: false,
            }
        },
        computed: {
            user: function() {
                return this.$store.getters.user;
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;
                this.$http.get(`/api/v1/domains?page=${this.current_page}&sort=${this.sort}&order=${this.order}`).then(this.refresh);
            },
            refresh({data}) {
                this.domains = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteDomain() {
                this.$http.delete(`/api/v1/domains/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The domain has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(this.current_page);

                if (this.$store.getters.current_domain_id === this.delete_id) {
                    this.$store.commit('updateDomainID', '');
                }

                this.$emitter.emit('domains_updated');
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            }
        }
    }
</script>
