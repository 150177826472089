<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{  title  }}</DialogTitle>
                            <div class="flex flex-row">
                                <div class="mt-2 flex items-center justify-center" ref="qrcode">
                                    <qrcode-vue id="qrcode" :value="qrCodeLink" :size="size" :foreground="foregroundColor" :background="backgroundColor" />
                                </div>
                                <div class="ml-8">
                                    <form-field>
                                        <form-label id="foregroundColor" label="QR Code Color"></form-label>
                                        <input type="color" id="foregroundColor" name="foregroundColor" v-model="foregroundColor" class="appearance-none block w-1/2 bg-white text-gray-700 border border-gray-400 shadow-sm mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple" />
                                    </form-field>
                                    <form-field>
                                        <form-label id="backgroundColor" label="Background Color"></form-label>
                                        <input type="color" id="backgroundColor" name="backgroundColor" v-model="backgroundColor" class="appearance-none block w-1/2 bg-white text-gray-700 border border-gray-400 shadow-sm mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple" />
                                    </form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="saveQRCode()">
                            {{ actionText }}
                        </form-button>

                        <form-button secondary @click="closeModal()">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'

    export default {
        name: 'qrcode-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            ExclamationTriangleIcon,
            FormButton,
            QrcodeVue,
            FormField,
            FormLabel,
        },
        emits: [
            'confirm',
            'cancel',
        ],
        props: {
            name: String,
            qrCodeLink: String,
            title: {
                type: String,
                default: 'QR Code',
            },
            description: {
                type: String,
                default: '',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            actionText: {
                type: String,
                default: 'Download',
            },
            height: {
                type: String,
                default: '370',
            },
            width: {
                type: String,
                default: '450',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'saved',
            'cancel',
        ],
        created() {
            this.foregroundColor = '#000';
            this.backgroundColor = '#fff';
        },
        data() {
            return {
                foregroundColor: '#000',
                backgroundColor: '#fff',
                size: 200,
            };
        },
        methods: {
            saveQRCode() {
                let img = document.getElementById('qrcode').toDataURL()
                var link = document.createElement("a");
                link.download = "QR.png";
                link.href = img;
                document.body.appendChild(link);
                link.click();
                link.remove();

                this.$emit('saved');
            },
            closeModal() {
                this.foregroundColor = '#000';
                this.backgroundColor = '#fff';
                this.$emit('cancel');
            },
        },
    };
</script>
