<template>
    <div>
        <layout-header title="Links">
            <layout-button href="/links/create">Create New Link</layout-button>
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by name or path and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_tag_ids"
                    :options="tags"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :multiple="true"
                    :preserve-search="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Tags"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_statuses"
                    :options="statuses"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :multiple="true"
                    :preserve-search="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_health_statuses"
                    :options="health_statuses"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :preserve-search="true"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Health Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('name')" hide-md>Name</table-header>
                    <table-header @click="updateSort('path')">Path</table-header>
                    <table-header @click="updateSort('redirect')" hide-lg>Redirect</table-header>
                    <table-header hide-sm>Tags</table-header>
                    <table-header @click="updateSort('views')">Views</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header @click="updateSort('health_status')" hide-lg>Health</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(link, index) in links" :key="link.id" :index="index">
                    <table-cell hide-sm bold>{{ link.id }}</table-cell>
                    <table-cell hide-md>{{ link.name }}</table-cell>
                    <table-cell>
                        <a :href="'http://'+link.domain.url+'/'+link.path" target="_blank">{{ link.path }}</a>
                    </table-cell>
                    <table-cell hide-lg>
                        <a :href="link.redirect" target="_blank">{{ link.redirect }}</a>
                    </table-cell>
                    <table-cell hide-sm>
                        <span :style="{backgroundColor: tag.color}" class="text-white font-bold py-1 px-2 my-2 rounded-full text-sm mx-1 inline-block whitespace-nowrap" v-for="tag in link.tags" v-bind:key="tag.id">{{ tag.name }}</span>
                    </table-cell>
                    <table-cell>
                        {{ Number(link.views_count).toLocaleString() }}
                    </table-cell>
                    <table-cell hide-sm>
                        <table-status-badge :status="link.status"></table-status-badge>
                    </table-cell>
                    <table-cell hide-lg no-wrap>
                        <table-status-badge :status="link.health_status"></table-status-badge>
                    </table-cell>
                    <table-cell last no-wrap>
                        <a href="javascript:void(0);" @click="showQRCode('http://'+link.domain.url+'/'+link.path)" class="mx-2"><i class="fas fa-qrcode"></i></a>
                        <a href="javascript:void(0);" @click="onCopy($event, 'http://'+link.domain.url+'/'+link.path);" class="mx-2"><i class="far fa-copy"></i></a>
                        <table-edit-link :href="{ name: 'edit_link', params: { id: link.id }}"></table-edit-link>
                        <table-delete-link @click="confirmDelete(link.id)"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination flex justify-center gap-2'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete Link?"
            description="Are you sure you want to delete this link?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteLink()"
            @cancel="open_delete_modal = false"
        />

        <QRCodeModal
            name="qrcode-modal"
            :qrCodeLink="qrcode_link"
            :show="open_qrcode_modal"
            @cancel="open_qrcode_modal = false;"
        />


    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'
    import QRCodeModal from '../../shared/modal/QRCodeModal'

    export default {
        name: 'ListLinks',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            ConfirmModal,
            FormButton,
            FormField,
            FormInput,
            FormSelect,
            QRCodeModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(this.current_page);
            this.fetchTags();
        },
        data() {
            return {
                links: [],
                delete_id: null,
                qrcode_link: null,
                page_count: 1,
                current_page: this.$route.query.page || 1,
                sort: this.$route.query.sort || 'desc',
                order: this.$route.query.order || 'id',
                tags: [],
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                health_statuses: [
                    {id: 'valid', name: 'Valid'},
                    {id: 'invalid_forbidden', name: 'Invalid - Forbidden'},
                    {id: 'invalid_notfound', name: 'Invalid - Not Found'},
                    {id: 'invalid_error', name: 'Invalid - Error'},
                    {id: 'invalid_other', name: 'Invalid - Other'},
                    {id: 'unknown', name: 'Unknown'},
                ],
                search: this.$route.query.search || '',
                search_tag_ids: [],
                search_statuses: [],
                search_health_statuses: [],
                open_delete_modal: false,
                open_qrcode_modal: false,
                qrcode_link: '',
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_tag_ids = this.search_tag_ids.map(tag => tag.id)
                let search_statuses = this.search_statuses.map(status => status.id)
                let search_health_statuses = this.search_health_statuses.map(status => status.id)

                this.$http.get(`/api/v1/links?domain_id=${this.$store.getters.current_domain_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_tag_ids=${search_tag_ids}&search_statuses=${search_statuses}&search_health_statuses=${search_health_statuses}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.links = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchTags() {
                this.$http.get('/api/v1/tags?per_page=-1').then(this.refreshTags);
            },
            refreshTags({ data }) {
                this.tags = data.data;
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteLink() {
                this.$http.delete(`/api/v1/links/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The link has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(this.current_page);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
            onCopy(e, text) {
                navigator.clipboard.writeText(text);

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'Copied link '+e.text,
                    duration: 5000,
                    speed: 1000
                });
            },
            showQRCode(link) {
                this.qrcode_link = link;
                this.open_qrcode_modal = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .redirectColumn {
        word-break: break-word;
    }
</style>
