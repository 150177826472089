<template>
    <div>
        <layout-header title="Home"></layout-header>

        <div class="flex flex-col m-5 bg-white">
            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md" v-if="home_data">
                <div class="">
                    <div class="bg-white my-2">
                        <div class="px-2 py-2">
                            <div class="flex">
                                <div class="flex-1 font-bold text-xl mb-2">{{ home_data.title }}</div>
                                <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="closeUpdateNotification()"><i class="far fa-times-circle"></i></a></h2>
                            </div>
                            <span v-html="home_data.notes_html"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md">
                <div class="">
                    <div class="bg-white my-2">
                        <div class="px-2 py-2">
                            <div class="font-bold text-xl mb-2">Hits/Misses</div>
                            <line-chart
                                :data="hit_miss_data"
                                :library="{hAxis: {format: 'MMM d'}}"
                                :colors="['#8368db', '#f96900']"
                            ></line-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-2">
                            <div class="px-2 py-2">
                                <div class="font-bold text-xl mb-2">Browsers</div>
                                <pie-chart
                                    :data="browser_summary_data"
                                    :colors="['#8368db', '#f96900', '#e8f9fa', '#e8faee', '#fee8d9', '#ede8fa']"
                                ></pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-2">
                            <div class="px-2 py-2">
                                <div class="font-bold text-xl mb-2">Operating Systems</div>
                                <pie-chart
                                    :data="os_summary_data"
                                    :colors="['#8368db', '#f96900', '#e8f9fa', '#e8faee', '#fee8d9', '#ede8fa']"
                                ></pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-2">
                            <div class="px-2 py-2">
                                <div class="font-bold text-xl mb-2">Views by State</div>
                                <geo-chart
                                    :data="state_summary_data"
                                    :library="{region: 'US', resolution: 'provinces'}"
                                    :colors="['#8368db', '#f96900']"
                                ></geo-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                    <div class="">
                        <div class="bg-white my-2">
                            <div class="px-2 py-2">
                                <div class="font-bold text-xl mb-2">Views by Country</div>
                                <geo-chart
                                    :data="country_summary_data"
                                    :colors="['#8368db', '#f96900']"
                                ></geo-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone';
    import LayoutApp from '../layouts/App';
    import LayoutHeader from '../shared/layout/Header'

    export default {
        name: 'home',
        components: {
            LayoutApp,
            LayoutHeader,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.fetchHitMiss();
            this.fetchBrowserSummary();
            this.fetchOSSummary();
            this.fetchStateSummary();
            this.fetchCountrySummary();
        },
        data() {
            return {
                hit_miss_data: null,
                browser_summary_data: null,
                os_summary_data: null,
                state_summary_data: null,
                country_summary_data: null,
                home_data: null,
            }
        },
        methods: {
            fetch(page) {
                this.$http.get(`/api/v1/home`).then(this.refresh);
            },
            refresh({data}) {
                this.home_data = data.latest_update
            },
            fetchHitMiss() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().format('YYYY-MM-DD');
                let id = '_' + Math.random().toString(36).substr(2, 9)
                this.$http.get(`/api/v1/reports/view/?domain_id=${this.$store.getters.current_domain_id}&type=hit_miss_summary&group_by=day&start=${start}&end=${end}&id=${id}`).then(this.refreshHitMiss);
            },
            refreshHitMiss({data}) {
                this.hit_miss_data = data.data;
            },
            fetchBrowserSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                let id = '_' + Math.random().toString(36).substr(2, 9)
                this.$http.get(`/api/v1/reports/view/?domain_id=${this.$store.getters.current_domain_id}&type=browser_summary&group_by=month&start=${start}&end=${end}&id=${id}`).then(this.refreshBrowserSummary);
            },
            refreshBrowserSummary({data}) {
                this.browser_summary_data = data.data;
            },
            fetchOSSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                let id = '_' + Math.random().toString(36).substr(2, 9)
                this.$http.get(`/api/v1/reports/view/?domain_id=${this.$store.getters.current_domain_id}&type=os_summary&group_by=month&start=${start}&end=${end}&id=${id}`).then(this.refreshOSSummary);
            },
            refreshOSSummary({data}) {
                this.os_summary_data = data.data;
            },
            fetchStateSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                let id = '_' + Math.random().toString(36).substr(2, 9)
                this.$http.get(`/api/v1/reports/view/?domain_id=${this.$store.getters.current_domain_id}&type=state_summary&group_by=month&start=${start}&end=${end}&id=${id}`).then(this.refreshStateSummary);
            },
            refreshStateSummary({data}) {
                this.state_summary_data = data.data;
            },
            fetchCountrySummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                let id = '_' + Math.random().toString(36).substr(2, 9)
                this.$http.get(`/api/v1/reports/view/?domain_id=${this.$store.getters.current_domain_id}&type=country_summary&group_by=month&start=${start}&end=${end}&id=${id}`).then(this.refreshCountrySummary);
            },
            refreshCountrySummary({data}) {
                this.country_summary_data = data.data;
            },
            closeUpdateNotification() {
                this.$http.post(`/api/v1/home/closeupdate`).then(({data}) => {
                    this.home_data = null
                });
            },
        }
    }
</script>
