<template>
    <div>
        <layout-header title="Create New Link"></layout-header>

        <form-section title="Link Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="link.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="link.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="path" name="path" label="Path" v-model="link.path"></form-input>
                <form-validation param="path" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="redirect" name="redirect" label="Redirect" v-model="link.redirect" placeholder="https://example.com"></form-input>
                <p class="text-xs italic"><a href="javascript:void(0);" @click="updateUTMDialog(link)">Manage UTM Params</a></p>
                <form-validation param="redirect" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="link.type">
                    <option value="default">Default</option>
                    <option value="predefined" v-if="settings.links.features.link_type_predefined">Predefined</option>
                    <option value="trackable_text" v-if="settings.links.features.link_type_trackable && permissions.text">Trackable (SeeBotText)</option>
                    <option value="trackable_chat" v-if="settings.links.features.link_type_trackable && permissions.chat">Trackable (SeeBotChat)</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="cache" name="cache" label="Cache" v-model="link.cache">
                    <option :value="true">Enabled</option>
                    <option :value="false">Disabled</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field v-if="tags.length > 0">
                <form-label id="tags" label="Tags"></form-label>
                <multiselect v-model="link.tags" :options="tags" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Tag"></multiselect>
                <form-validation param="tags" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="expiration" name="expiration" label="Link Expiration" v-model="link.options.expiration.type">
                    <option :value="null">Do not expire</option>
                    <option value="datetime">Expire on Date/Time</option>
                </form-select>
                <form-validation param="options.expiration.type" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Link Expiration Details" v-if="settings.links.features.link_expiration && link.options.expiration.type">
            <form-field wide>
                <form-validation param="options.expiration" :errors="errors" />
            </form-field>

            <form-field v-if="link.options.expiration.type === 'datetime'">
                <form-date-picker v-model="link.options.expiration.datetime" id="expiration_datetime" label="Expiration Date/Time"></form-date-picker>
                <form-validation param="options.expiration" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Dynamic Routing Details" v-if="settings.links.features.conditional_redirects && link.type !== 'predefined'">
            <form-field wide>
                <form-validation param="options.targeting" :errors="errors" />
            </form-field>

            <!-- Row 1 -->
            <form-field wide>
                <div class="border-solid border-2 border-gray-300 bg-gray-100 mb-4 mr-4" v-for="(target, target_index) in link.options.targeting.targets" v-bind:key="target_index">
                    <h3 class="p-4">Target Details</h3>
                    <p class="text-red-500 text-xs italic" v-if="errors && errors['options.targeting.targets.'+target_index]">{{ errors['options.targeting.targets.'+target_index][0] }}</p>
                    <p class="text-red-500 text-xs italic" v-if="errors && errors['options.targeting.targets.'+target_index+'.operator']">{{ errors['options.targeting.targets.'+target_index+'.operator'][0] }}</p>

                    <div class="" v-for="(item, index) in target.blocks" v-bind:key="index">
                        <div class="border-solid border-2 border-gray-600 bg-gray-300 mb-4 mx-4">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors['options.targeting.targets.'+target_index+'.blocks.'+index]">{{ errors['options.targeting.targets.'+target_index+'.blocks.'+index][0] }}</p>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors['options.targeting.targets.'+target_index+'.blocks.'+index+'.operator']">{{ errors['options.targeting.targets.'+target_index+'.blocks.'+index+'.operator'][0] }}</p>

                            <div class="flex flex-wrap mt-3 mb-6" v-for="(row, row_index) in item.items" v-bind:key="row_index">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors['options.targeting.targets.'+target_index+'.blocks.'+index+'.items.'+row_index]">{{ errors['options.targeting.targets.'+target_index+'.blocks.'+index+'.items.'+row_index][0] }}</p>

                                <div class="w-full md:w-1/4 px-3 md:mb-0">
                                    <div class="relative">
                                        <form-select v-model="row.option" @change="updateRowOption(row)">
                                            <option :value="null">- Select Option -</option>
                                            <option value="date">When Date</option>
                                            <option value="day_of_week">When Day of Week</option>
                                            <option value="hour_of_day">When Hour of Day</option>
                                            <option value="device_type">When Device Type</option>
                                            <option value="browser">When Browser</option>
                                            <option value="os">When OS</option>
                                        </form-select>
                                    </div>
                                    <form-validation :param="'options.targeting.targets.'+target_index+'.blocks.'+index+'.items.'+row_index+'.option'" :errors="errors" />
                                </div>
                                <div class="w-full md:w-1/4 px-3 md:mb-0">
                                    <div class="relative" v-if="row.option">
                                        <form-select v-model="row.operator">
                                            <option value="=">is equal to</option>
                                            <option value="!=">is not equal to</option>
                                            <option value="<" v-if="(row.option === 'date' || row.option === 'day_of_week' || row.option === 'hour_of_day')">is less than</option>
                                            <option value="<=" v-if="(row.option === 'date' || row.option === 'day_of_week' || row.option === 'hour_of_day')">is less than or equal to</option>
                                            <option value=">" v-if="(row.option === 'date' || row.option === 'day_of_week' || row.option === 'hour_of_day')">is greater than</option>
                                            <option value=">=" v-if="(row.option === 'date' || row.option === 'day_of_week' || row.option === 'hour_of_day')">is greater than or equal to</option>
                                        </form-select>
                                    </div>
                                    <form-validation :param="'options.targeting.targets.'+target_index+'.blocks.'+index+'.items.'+row_index+'.operator'" :errors="errors" />
                                </div>
                                <div class="w-full md:w-1/4 px-3 md:mb-0">
                                    <div class="relative" v-if="row.option && row.option === 'browser'">
                                        <form-select v-model="row.value">
                                            <option value="chrome">Chrome</option>
                                            <option value="firefox">Firefox</option>
                                            <option value="safari">Safari</option>
                                            <option value="edge">Edge</option>
                                            <option value="ie">IE</option>
                                            <option value="opera">Opera</option>
                                            <option value="other">Other</option>
                                        </form-select>
                                    </div>
                                    <div class="relative" v-if="row.option && row.option === 'os'">
                                        <form-select v-model="row.value">
                                            <option value="windows">Windows</option>
                                            <option value="macos">MacOS</option>
                                            <option value="linux">Linux</option>
                                            <option value="ios">iOS</option>
                                            <option value="android">Android</option>
                                            <option value="other">Other</option>
                                        </form-select>
                                    </div>
                                    <div class="relative" v-if="row.option && row.option === 'device_type'">
                                        <form-select v-model="row.value">
                                            <option value="desktop">Desktop</option>
                                            <option value="tablet">Tablet</option>
                                            <option value="phone">Phone</option>
                                            <option value="other">Other</option>
                                        </form-select>
                                    </div>
                                    <div class="relative" v-if="row.option && row.option === 'day_of_week'">
                                        <form-select v-model="row.value">
                                            <option value="7">Sunday</option>
                                            <option value="1">Monday</option>
                                            <option value="2">Tuesday</option>
                                            <option value="3">Wednesday</option>
                                            <option value="4">Thursday</option>
                                            <option value="5">Friday</option>
                                            <option value="6">Saturday</option>
                                        </form-select>
                                    </div>
                                    <div class="relative" v-if="row.option && row.option === 'hour_of_day'">
                                        <form-select v-model="row.value">
                                            <option value="0">12 am</option>
                                            <option value="1">1 am</option>
                                            <option value="2">2 am</option>
                                            <option value="3">3 am</option>
                                            <option value="4">4 am</option>
                                            <option value="5">5 am</option>
                                            <option value="6">6 am</option>
                                            <option value="7">7 am</option>
                                            <option value="8">8 am</option>
                                            <option value="9">9 am</option>
                                            <option value="10">10 am</option>
                                            <option value="11">11 am</option>
                                            <option value="12">12 pm</option>
                                            <option value="13">1 pm</option>
                                            <option value="14">2 pm</option>
                                            <option value="15">3 pm</option>
                                            <option value="16">4 pm</option>
                                            <option value="17">5 pm</option>
                                            <option value="18">6 pm</option>
                                            <option value="19">7 pm</option>
                                            <option value="20">8 pm</option>
                                            <option value="21">9 pm</option>
                                            <option value="22">10 pm</option>
                                            <option value="23">11 pm</option>
                                        </form-select>
                                    </div>
                                    <div class="relative" v-if="row.option && row.option === 'date'">
                                        <form-date-picker v-model="row.value"></form-date-picker>
                                    </div>
                                    <form-validation :param="'options.targeting.targets.'+target_index+'.blocks.'+index+'.items.'+row_index+'.value'" :errors="errors" />
                                </div>
                                <div class="w-full md:w-1/6 cursor-pointer h-full mx-2 flex">
                                    <form-button positive-action @click="addRow(index, target_index, row_index)"><i class="fas fa-plus-circle"></i> OR</form-button>
                                    <form-button negative-action v-if="target.blocks.length > 1 || item.items.length > 1" @click="removeRow(row_index, index, target_index)"><i class="fas fa-minus-circle"></i></form-button>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap mx-3 mb-6 pl-2">
                            <form-button positive-action @click="addBlock(target_index, index)"><i class="fas fa-plus-circle"></i> AND</form-button>
                        </div>
                    </div>

                    <div class="flex flex-wrap my-3">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <form-label :for="'target_'+target_index+'_redirect'" label="Redirect"></form-label>
                            <form-input :id="'target_'+target_index+'_redirect'" :name="'target_'+target_index+'_redirect'" v-model="target.redirect" placeholder="https://example.com"></form-input>
                            <p class="text-xs italic"><a href="javascript:void(0);" @click="updateUTMDialog(target)">Manage UTM Params</a></p>
                            <form-validation :param="'options.targeting.targets.'+target_index+'.redirect'" :errors="errors" />
                        </div>
                        <div class="w-full md:w-1/2 cursor-pointer h-full pl-6 pt-6 text-right">
                            <form-button negative-action @click="removeTarget(target_index)"><i class="fas fa-minus-circle"></i> Remove Target</form-button>
                        </div>
                    </div>

                </div>
            </form-field>

            <form-field wide>
                <form-button positive-action @click="addTarget()"><i class="fas fa-plus-circle"></i> Add Target</form-button>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Link
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <UTMModal
            name="utm-modal"
            v-model:utmSource="utm_source"
            v-model:utmMedium="utm_medium"
            v-model:utmName="utm_name"
            v-model:utmTerm="utm_term"
            v-model:utmCampaign="utm_campaign"
            :show="open_utm_modal"
            @update="updateUTMParams()"
            @cancel="clearUTMParams()"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone'
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormLabel from '../../shared/form/Label';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import UTMModal from '../../shared/modal/UTMModal';
    import FormDatePicker from '../../shared/form/DatePicker';

    export default {
        name: 'CreateLink',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormLabel,
            FormInput,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            UTMModal,
            FormDatePicker,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchTags();
            this.fetchSettings();
        },
        data() {
            return {
                utm_source: '',
                utm_medium: '',
                utm_name: '',
                utm_term: '',
                utm_campaign: '',
                utm_field: null,
                open_utm_modal: false,
                link: {
                    name: '',
                    domain_id: this.$store.getters.current_domain_id,
                    path: '',
                    redirect: '',
                    status: 'active',
                    cache: true,
                    client_id: '',
                    tags: [],
                    type: 'default',
                    options: {
                            expiration: {
                                type: null,
                                datetime: null,
                            },
                            marketing: {

                            },
                            targeting: {
                                targets: [
                                ],
                            },
                    },
                },
                tags: [],
                errors: [],
                permissions: {
                    chat: false,
                    text: false,
                    talk: false,
                    lookup: false,
                    connect: false,
                    links: true,
                },
                settings: {
                    links: {
                        features: {
                            reports: false,
                            qr_codes: false,
                            custom_og_tags: false,
                            link_expiration: false,
                            app_deep_linking: false,
                            tracking_scripts: false,
                            conditional_redirects: false,
                            link_type_predefined: false,
                            link_type_dynamic: false,
                        },
                        maximum_links: null,
                        maximum_custom_domains: null,
                    },
                },
                show_help_section: null,
            }
        },
        methods: {
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;
            },
            fetchTags() {
                this.$http.get('/api/v1/tags?per_page=-1').then(this.refreshTags);
            },
            refreshTags({ data }) {
                this.tags = data.data;
            },
            save() {
              let item = {
                name: this.link.name,
                domain_id: this.link.domain_id,
                path: this.link.path,
                redirect: this.link.redirect,
                status: this.link.status,
                cache: this.link.cache,
                type: this.link.type,
                options: {...this.link.options},
                tags: []
              };

              for (let i in this.link.tags) {
                  item.tags.push(this.link.tags[i].id)
              }

              if (item.options.expiration.type === 'datetime' && item.options.expiration.datetime !== '') {
                  item.options.expiration.datetime = moment(item.options.expiration.datetime).format('YYYY-MM-DD HH:mm:ss')
              }

              this.$http.post('/api/v1/links', item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The link has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_links'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the link',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addTarget() {
                this.link.options.targeting.targets.push({
                    operator: 'AND',
                    blocks: [{
                        operator: 'OR',
                        items: [{
                            option: null,
                            operator: '=',
                            value: null
                        }]
                    }],
                    redirect: '',
                })
            },
            removeTarget(target_index) {
                this.link.options.targeting.targets.splice(target_index, 1);
            },
            addBlock(target_index, block_index) {
                this.link.options.targeting.targets[target_index].blocks.splice(block_index + 1, 0, {
                    operator: 'OR',
                    items: [{
                        option: null,
                        operator: '=',
                        value: null
                    }]
                })
            },
            addRow(block_index, target_index, row_index) {
                this.link.options.targeting.targets[target_index].blocks[block_index].items.splice(row_index + 1, 0, {
                    option: null,
                    operator: '=',
                    value: null
                })
            },
            removeRow(index, block_index, target_index) {
                this.link.options.targeting.targets[target_index].blocks[block_index].items.splice(index, 1);

                if (this.link.options.targeting.targets[target_index].blocks[block_index].items.length === 0) {
                    this.link.options.targeting.targets[target_index].blocks.splice(block_index, 1);
                }
            },
            updateRowOption(row) {
                row.operator = '='
            },
            updateUTMDialog(item) {
                this.utm_field = item

                if (this.utm_field.redirect.indexOf('?') >= 0) {
                    let query_string = this.utm_field.redirect.substring(this.utm_field.redirect.indexOf('?') + 1)
                    let query_params = query_string.split('&')

                    for (var i = 0 ; i < query_params.length ; ++i) {
                        let param_items = query_params[i].split('=')
                        let param_name = decodeURIComponent(param_items[0])

                        if (param_name === 'utm_source') {
                            this.utm_source = param_items[1]
                        } else if (param_name === 'utm_medium') {
                            this.utm_medium = param_items[1]
                        } else if (param_name === 'utm_name') {
                            this.utm_name = param_items[1]
                        } else if (param_name === 'utm_term') {
                            this.utm_term = param_items[1]
                        } else if (param_name === 'utm_campaign') {
                            this.utm_campaign = param_items[1]
                        }
                    }
                }

                this.open_utm_modal = true;
            },
            updateUTMParams() {
                // Parse the URL, and replace any items already in the query string.
                // Then, add any items not already set.
                var new_url = ''

                if (this.utm_field.redirect.indexOf('?') >= 0) {
                    new_url = this.utm_field.redirect.substring(0, this.utm_field.redirect.indexOf('?') + 1)
                    let query_string = this.utm_field.redirect.substring(this.utm_field.redirect.indexOf('?') + 1)
                    let query_params = query_string.split('&')

                    for (var i = 0 ; i < query_params.length ; ++i) {
                        if (query_params[i] === '') {
                            continue
                        }

                        let param_items = query_params[i].split('=')
                        let param_name = decodeURIComponent(param_items[0])
                        let param_value = param_items[1]

                        if (param_name === 'utm_source') {
                            param_value = this.utm_source
                            this.utm_source = ''
                        } else if (param_name === 'utm_medium') {
                            param_value = this.utm_medium
                            this.utm_medium = ''
                        } else if (param_name === 'utm_name') {
                            param_value = this.utm_name
                            this.utm_name = ''
                        } else if (param_name === 'utm_term') {
                            param_value = this.utm_term
                            this.utm_term = ''
                        } else if (param_name === 'utm_campaign') {
                            param_value = this.utm_campaign
                            this.utm_campaign = ''
                        }

                        new_url += param_name + '=' + param_value + '&'
                    }
                } else {
                    new_url = this.utm_field.redirect + '?'
                }

                if (this.utm_source !== '') {
                    new_url += 'utm_source=' + this.utm_source + '&'
                }

                if (this.utm_medium !== '') {
                    new_url += 'utm_medium=' + this.utm_medium + '&'
                }

                if (this.utm_name !== '') {
                    new_url += 'utm_name=' + this.utm_name + '&'
                }

                if (this.utm_term !== '') {
                    new_url += 'utm_term=' + this.utm_term + '&'
                }

                if (this.utm_campaign !== '') {
                    new_url += 'utm_campaign=' + this.utm_campaign + '&'
                }

                if (new_url[new_url.length - 1] === '&') {
                    new_url = new_url.substring(0, new_url.length - 1)
                }

                this.utm_field.redirect = new_url
                this.clearUTMParams()
            },
            clearUTMParams() {
                this.utm_source = ''
                this.utm_medium = ''
                this.utm_name = ''
                this.utm_term = ''
                this.utm_campaign = ''
                this.utm_field = null

                this.open_utm_modal = false;
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
