<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">UTM Code</DialogTitle>
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">Manage the UTM parameters for your redirect URL.</p>
                                </div>
                                <div class="mt-8">
                                    <form-field>
                                        <form-input id="utm_source" name="utm_source" label="UTM Source" v-model="utm_source"></form-input>
                                    </form-field>
                                    <form-field>
                                        <form-input id="utm_medium" name="utm_medium" label="UTM Medium" v-model="utm_medium"></form-input>
                                    </form-field>
                                    <form-field>
                                        <form-input id="utm_name" name="utm_name" label="UTM Name" v-model="utm_name"></form-input>
                                    </form-field>
                                    <form-field>
                                        <form-input id="utm_term" name="utm_term" label="UTM Term" v-model="utm_term"></form-input>
                                    </form-field>
                                    <form-field>
                                        <form-input id="utm_campaign" name="utm_campaign" label="UTM Campaign" v-model="utm_campaign"></form-input>
                                    </form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('update')">
                            {{ actionText }}
                        </form-button>

                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button';
    import FormInput from '../../shared/form/Input';
    import FormField from '../../shared/form/Field';

    export default {
        name: 'utm-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormInput,
            FormField,
        },
        props: {
            name: String,
            utmSource: String,
            utmMedium: String,
            utmName: String,
            utmTerm: String,
            utmCampaign: String,
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            actionText: {
                type: String,
                default: 'Update',
            },
            height: {
                type: String,
                default: '620',
            },
            width: {
                type: String,
                default: '500',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'update',
            'cancel',
            'update:utmSource',
            'update:utmMedium',
            'update:utmName',
            'update:utmTerm',
            'update:utmCampaign',
        ],
        computed: {
            utm_source: {
                get() {
                    return this.utmSource
                },
                set(val) {
                    this.$emit('update:utmSource', val);
                }
            },
            utm_medium: {
                get() {
                    return this.utmMedium
                },
                set(val) {
                    this.$emit('update:utmMedium', val);
                }
            },
            utm_name: {
                get() {
                    return this.utmName
                },
                set(val) {
                    this.$emit('update:utmName', val);
                }
            },
            utm_term: {
                get() {
                    return this.utmTerm
                },
                set(val) {
                    this.$emit('update:utmTerm', val);
                }
            },
            utm_campaign: {
                get() {
                    return this.utmCampaign
                },
                set(val) {
                    this.$emit('update:utmCampaign', val);
                }
            },
        },
    };
</script>
