<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <date-picker
            input-class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-full shadow-sm py-2 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :input-attr="{id: id, name: name, readonly: readonly}"
            v-model:value="input_value"
            :format="dateonly_format"
            :time-picker-options="time_options"
            :lang="lang"
            :type="type"
            :range="range"
            prefix-class="sbr"
            :open="open"
            @open="handleOpen"
            @close="handleClose"
            @change="(date, type) => {
                handleClose();
                $emit('update:modelValue', date)
            }"
        ></date-picker>
    </div>
</template>

<script>
    import DatePicker from 'vue-datepicker-next';
    import FormLabel from './Label';

    export default {
        name: 'form-datepicker',
        components: {
            DatePicker,
            FormLabel,
        },
        model: {
        },
        props: {
            id: String,
            name: String,
            label: String,
            type: {
                type: String,
                default: 'date',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            range: {
                type: Boolean,
                default: false,
            },
            modelValue: {
                default: null,
            },
        },
        data () {
            return {
                open: false,
                format: 'YYYY-MM-DD hh:mm a ZZ',
                dateonly_format: 'YYYY-MM-DD',
                time_options: {start: '00:00', step:'00:30' , end: '23:30', format: 'hh:mm' },
                lang: {
                    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                    placeholder: {
                        date: 'Select Date/Time',
                    },
                },
            };
        },
        methods: {
            handleOpen() {
                this.open = true
            },
            handleClose() {
                this.open = false
            },
        },
        computed: {
            input_value: {
                get() {
                    return this.modelValue
                },
                set(val) {
                    this.$emit('change', val)
                }
            },
        },
    }
</script>
